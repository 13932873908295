/** @format */

.basicdnd-container {
  padding: 2px;
  display: flex;
  flex-direction: column;
  gap: 2px;

  &.x-axis {
    flex-direction: row !important;
  }

  .basicdnd-item {
    padding: 2px 5px;
    list-style: none;
    width: 100%;
    // background: transparentize(black, 0.8);
    border-radius: 2px;
    // transition: 0.3s ease-in-out;
    cursor: pointer;
    background: transparentize(#5b73e8, 0.9);

    &:hover {
      //   transition: 0.3s ease-in-out;
      background: transparentize(#5b73e8, 0.6);
      color: white;
    }
  }
}
