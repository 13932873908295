/** @format */

.module-info {
  transition: all 0.3s ease-in;
  &:hover {
    transform: scale(1.02);
    font-weight: 600;
  }
}

.tree {
  margin: 0;
  padding: 0;
  list-style: none;
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    margin-left: 1em;
    position: relative;
    ul {
      margin-left: 0.5em;
    }
    &:before {
      content: '';
      display: block;
      width: 0;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      border-left: 1px solid;
    }
    li {
      &:before {
        content: '';
        display: block;
        width: 10px;
        height: 0;
        border-top: 1px solid;
        margin-top: -1px;
        position: absolute;
        top: 1em;
        left: 0;
      }
      &:last-child {
        &:before {
          background: #fff;
          height: auto;
          top: 1em;
          bottom: 0;
        }
      }
    }
  }
  li {
    margin: 0;
    cursor: pointer;
    padding: 0 1em;
    line-height: 2em;
    color: #495057;
    font-weight: 400;
    position: relative;
    .expand {
      display: block;
    }
    .collapse {
      display: none;
    }
    a {
      text-decoration: none;
      color: #495057;
    }
    button {
      text-decoration: none;
      color: #495057;
      border: none;
      background: transparent;
      margin: 0px 0px 0px 0px;
      padding: 0px 0px 0px 0px;
      outline: 0;
      &:active {
        text-decoration: none;
        color: #495057;
        border: none;
        background: transparent;
        margin: 0px 0px 0px 0px;
        padding: 0px 0px 0px 0px;
        outline: 0;
      }
      &:focus {
        text-decoration: none;
        color: #495057;
        border: none;
        background: transparent;
        margin: 0px 0px 0px 0px;
        padding: 0px 0px 0px 0px;
        outline: 0;
      }
    }
  }
}
.indicator {
  margin-right: 5px;
}
