/** @format */

.floating-context-menu {
  opacity: 0;

  pointer-events: none;
  position: absolute;
  left: 0;
  top: 50%;
  max-width: fit-content;
  transform: translateY(-50%) scale(0.6);
  display: flex;
  background: white;
  box-shadow: //inset 0 0 10px transparentize(black, 0.8),
    0 0 20px transparentize(black, 0.8);
  border-radius: 5px;
  margin: 0 10px;
  overflow: hidden;
  transition: 0.5s ease-in-out;

  & > .ctxt-menu-item {
    display: flex;
    padding: 6px 10px !important;
    gap: 10px;
    text-transform: capitalize;
    cursor: pointer;

    &:hover {
      background-color: #5b73e8;
      color: white;
    }
  }
}
