/** @format */

.shortcut {
  .shortcut-group {
    .shortcut-list {
      .shortcut-item {
        width: 120px;
        height: 50px;
        // overflow: hidden;
        .shortcut-label {
          text-overflow: ellipsis;
        }
        transition: 0.3s ease-in-out;
        background-color: transparentize(black, 0.95);
        color: black;

        &:hover {
          background-color: transparentize(black, 0.4);
          color: white;
        }

        &.shortcut-active {
          background-color: #5b73e8;
          color: white;
        }
      }
    }
  }
}
