// :root CSS variables

:root{

  // Vertical Sidebar - Default Light

  --#{$variable-prefix}sidebar-bg: #ffffff;
  --#{$variable-prefix}sidebar-menu-item-color: #7b8190;
  --#{$variable-prefix}sidebar-menu-sub-item-color: #7b8190;
  --#{$variable-prefix}sidebar-menu-item-icon-color: #7b8190;
  --#{$variable-prefix}sidebar-menu-item-hover-color: #383c40;
  --#{$variable-prefix}sidebar-menu-item-active-color: #5b73e8;
  --#{$variable-prefix}sidebar-menu-item-active-bg-color: #f3f8fb;
  --#{$variable-prefix}sidebar-menu-item-active-bg-color-dark: #293552;

    // Vertical Sidebar - Dark
  --#{$variable-prefix}sidebar-dark-bg: #1c2742; //2c313a
  --#{$variable-prefix}sidebar-dark-menu-item-color: #8c98bb;
  --#{$variable-prefix}sidebar-dark-menu-sub-item-color: #7b85a2;
  --#{$variable-prefix}sidebar-dark-menu-item-icon-color: #6d7794;
  --#{$variable-prefix}sidebar-dark-menu-item-hover-color: #ffffff;
  --#{$variable-prefix}sidebar-dark-menu-item-active-color: #ffffff;

    // Topbar - Deafult Light
  --#{$variable-prefix}header-bg: #ffffff;
  --#{$variable-prefix}header-item-color: #555b6d;

    // Topbar - Dark
  --#{$variable-prefix}header-dark-bg: #2f374e;
  --#{$variable-prefix}header-dark-item-color: #e9ecef;

    // Footer
  --#{$variable-prefix}footer-bg: #f2f2f5;
  --#{$variable-prefix}footer-color: #74788d;

  // Horizontal nav
  --#{$variable-prefix}topnav-bg:   #ffffff;

  --#{$variable-prefix}menu-item-color: #7b8190;
  --#{$variable-prefix}menu-item-active-color: #5b73e8;

    // body
    --#{$variable-prefix}body-bg: #f5f6f8;
    --#{$variable-prefix}body-color: #{$gray-700};
    --#{$variable-prefix}body-color-rgb: #{to-rgb($body-color)};

      // apex charts
  --#{$variable-prefix}apex-grid-color: #f8f9fa;

        // Display
    --#{$variable-prefix}display-none: none;
    --#{$variable-prefix}display-block: block;

      // card-title-desc
  --#{$variable-prefix}card-title-desc: #{$gray-600};
  
  // component variable

  // theme-color
  --#{$variable-prefix}light: #{$gray-200};
  --#{$variable-prefix}light-rgb: #{to-rgb($gray-200)};
  --#{$variable-prefix}dark: #{$gray-900};
  --#{$variable-prefix}dark-rgb: #{to-rgb($gray-900)};
  --#{$variable-prefix}text-muted: #{$gray-600};

  // link
  --#{$variable-prefix}link-color: #{$primary};
  --#{$variable-prefix}link-hover-color: #{$primary};

  // Border variable
  --#{$variable-prefix}border-color:  #{$gray-300};

  // dropdown
  --#{$variable-prefix}dropdown-bg: #{$white};
  --#{$variable-prefix}dropdown-link-color: #{$gray-900};
  --#{$variable-prefix}dropdown-link-hover-color: #{shade-color($gray-900, 5%)};
  --#{$variable-prefix}dropdown-link-hover-bg: #{$gray-100};
  --#{$variable-prefix}dropdown-border-width: 0px;

  // card
  --#{$variable-prefix}card-bg: #{$white};
  --#{$variable-prefix}card-cap-bg: #{$white};
  --#{$variable-prefix}card-border-color: #{$gray-300};
  --#{$variable-prefix}card-logo-dark: block;
  --#{$variable-prefix}card-logo-light: none;

  // modal
  --#{$variable-prefix}modal-bg: #{$white};
  --#{$variable-prefix}modal-content-bg: #{$white};
  --#{$variable-prefix}modal-content-border-color: #{$gray-300};


  // nav tabs
  --#{$variable-prefix}nav-tabs-link-active-color: #{$gray-700};
  --#{$variable-prefix}nav-tabs-link-active-bg: #{$body-bg};

  // accordion
  --#{$variable-prefix}accordion-button-active-color: #{shade-color($primary, 10%)};
  --#{$variable-prefix}accordion-bg: #{$white};
  --#{$variable-prefix}accordion-button-bg: #{$gray-200};

    // table
    --#{$variable-prefix}table-color: #{$body-color};

    // Boxed layout 
    --#{$variable-prefix}boxed-body-bg:       #e9ebf0;

  // progress
  --#{$variable-prefix}progress-bg: #{$gray-200};

  // toast
  --#{$variable-prefix}toast-background-color: #{rgba($white, .85)};
  --#{$variable-prefix}toast-border-color: #{rgba($black, .1)};
  --#{$variable-prefix}toast-header-border-color: #{rgba($black, .05)};

  //list
  --#{$variable-prefix}list-group-hover-bg: #{$gray-100};

  // popover
  --#{$variable-prefix}popover-bg: #{$white};

  // pagination
  --#{$variable-prefix}pagination-hover-bg: #{$gray-200};

  // form
  --#{$variable-prefix}input-bg: #{$white};
  --#{$variable-prefix}input-group-addon-border-color: #{$gray-400};
  --#{$variable-prefix}input-border: #{$gray-400};
  --#{$variable-prefix}input-border-color: #{$gray-400};
  --#{$variable-prefix}input-focus-border: #{tint-color($component-active-bg, 50%)};
  --#{$variable-prefix}input-disabled-bg: #{$gray-200};

  // input
  --#{$variable-prefix}input-placeholder-color: #{$gray-600};
  --#{$variable-prefix}input-group-addon-bg: #{$gray-200};

  //check
  --#{$variable-prefix}input-check-border: var(--#{$variable-prefix}input-border);

  
}
