/** @format */

.specimen-item {
  position: relative;
  overflow: hidden;
  border-radius: 10px;
  .edit-specimen-item {
    overflow: hidden;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 5px;
    box-shadow: #5b73e8 0 0 30px;
    pointer-events: none;
    opacity: 0;
    transition: 0.3s ease-in-out;

    .option {
      transition: 0.4s ease-in-out;
      cursor: pointer;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      gap: 4px;
      padding: 10px;
      font-weight: normal;
      margin-bottom: 0;

      span {
        font-size: smaller;
      }

      &:hover {
        color: white;
        // color: #5b73e8;
        background: transparentize(#5b73e8, 0);
      }
    }
  }
  &:hover {
    .edit-specimen-item {
      pointer-events: all;
      opacity: 1;
    }
  }
  .image-loading {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    width: 100%;
    height: 100%;
    background: transparentize(black, 0.5);
    align-items: center;
    justify-content: center;
  }
}

.view-specimen {
  pointer-events: none;
  opacity: 0;
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: transparentize(black, 0.8);
  top: 0;
  left: 0;
  z-index: 10000;
  display: flex;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(5px);

  &.shown {
    pointer-events: all;
    opacity: 1;
  }

  .close-it {
    position: fixed;
    right: 3vw;
    top: 3vh;
    font-size: 50px;
    color: rgb(252, 62, 62);
    cursor: pointer;
    text-shadow: none;
  }
}


.view-qr-code {
  pointer-events: none;
  opacity: 0;
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: transparentize(black, 0.8);
  top: 0;
  left: 0;
  z-index: 999999999999;
  display: flex;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(5px);

  &.shown {
    pointer-events: all;
    opacity: 1;
  }

  .close-it {
    position: fixed;
    right: 3vw;
    top: 3vh;
    font-size: 50px;
    color: rgb(252, 62, 62);
    cursor: pointer;
    text-shadow: none;
  }
}
