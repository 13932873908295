/** @format */

$prim: #5b73e8;

.context-menu {
  //   padding: 5px;
  box-shadow: 0 0 20px transparentize(black, 0.6);
  position: fixed;
  max-width: fit-content;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0;
  overflow: hidden;
  z-index: 9999999;
  //   border: solid 1px $prim;
  //   gap: 5px;

  .ctxt-menu-item {
    transition: 0.3s ease-in-out;
    cursor: pointer;
    padding: 5px 10px;
    text-transform: capitalize;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    i {
      margin-right: 5px;
      margin-left: -5px;
    }
    &:hover {
      background: $prim;
      color: white;
    }
  }
}
