/** @format */

.doc-container {
  background: transparentize($color: black, $amount: 0.8);

  .doc-content {
    box-shadow: transparentize(black, 0.4) 0 0 30px;
    max-width: 90%;
    max-height: 90%;
    overflow: hidden;
    // position: relative;

    .doc-label {
      position: absolute;
      bottom: 10px;
      border-radius: 20px;
      left: 50%;
      box-shadow: black 0 0 10px;
      transform: translateX(-50%);
    }
  }
}
