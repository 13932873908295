/** @format */
.breadcrumb-item {
  opacity: 0.6;
  transition: ease-in-out 0.3s;
  &:hover {
    opacity: 0.8;
  }
  &.active {
    opacity: 1;
  }
}
