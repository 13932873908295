/** @format */
.user-pill {
  position: relative;
  .user-btn {
    display: flex;
    align-items: center;
    justify-content: center;

    .user-avatar {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 40px;
      background-color: transparentize($color: black, $amount: 0.9);
      border-radius: 50%;
      margin-right: 5px;
    }
  }

  .user-dropdown {
    position: inherit;
    position: absolute;
    box-shadow: transparentize(black, 0.6) 0 0 10px;
    border-radius: 5px;
    top: calc(100% - 2px);
    width: 200px;
    display: flex;
    flex-direction: column;

    .separator {
      background-color: transparentize(black, 0.8);
      height: 1px;
      margin: 10px 10px;
    }

    .user-items {
      display: flex;
      flex-direction: column;
      padding: 10px;
      gap: 5px;

      .user-item {
        display: flex;
        align-items: center;
        text-transform: capitalize;
        .user-key {
          background-color: transparentize(black, 0.5);
          color: white;
          margin-right: 5px;
          padding: 5px;
          border-radius: 5px;
        }
      }
    }

    .log-out {
      background: transparentize(black, 0.9);
      border: none;
      cursor: pointer;
      transition: 0.3s ease-in-out;
      border-radius: 5px;

      &:hover {
        background: transparentize(black, 0.3);
        color: white;
      }
    }
  }
}
